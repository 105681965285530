@import "~tutch-kiosk-core/styles/_global.scss";
@import "_mixins.scss";

:root {
   --kiosk-font-family-default: "Helvetica Neue";
   //
   --kiosk-scrollbar-track-color: var(--app-color--green-light);
   --kiosk-scrollbar-thumb-color: var(--app-color--green-dark);
   //
   --kiosk-input-border-default: 1px solid rgb(34, 34, 34);
   --kiosk-input-border-focused: 2px solid rgb(34, 34, 34);
   --kiosk-input-background-focused: rgb(235, 239, 239);
}

.kiosk-toolbar {
   .header-bar {
      background: var(--app-color--green-dark);
      color: var(--app-color--white);
      .logo-panel {
         width: 300px;
         .logo-image {
            content: url(/assets/icons/kiosk/client-logo.svg);
            width: 250px;
         }
      }
      &:has(> .kiosk-chat-invite) {
         .custom-content {
            right: 63px;
         }
         > .kiosk-chat-invite {
            position: absolute;
            right: 0;
         }
      }
   }
   &.small-header-bar {
      .header-bar {
         .navigation-buttons button {
            transform: scale(0.9);
         }
         .logo-panel,
         .kiosk-cart-status-box,
         .kiosk-customer-info-box {
            display: none;
         }
         .kiosk-product-search-box {
            margin-right: 5px;
         }
         .kiosk-product-search-result-view {
            margin-top: 5px;
         }
         &:has(> .kiosk-chat-invite) {
            .kiosk-product-search-box {
               margin-right: 35px;
            }
         }
      }
   }

   .footer-bar {
      background: var(--app-color--green-dark);
      color: var(--app-color--white);
      .info {
         letter-spacing: 0.2px;
      }
      z-index: 1;
   }
}

.kiosk-cart-status-box {
   .mat-icon {
      width: 70px !important;
      margin-right: -15px;
      margin-top: 8px;
      circle {
         fill: var(--app-color--white);
      }
      path:not(:last-child) {
         fill: var(--app-color--white);
      }
      path:last-child {
         stroke: var(--app-color--white);
      }
   }
   .mat-chip.mat-standard-chip .badge {
      background: var(--app-color--red);
      color: var(--app-color--white);
      left: 6px;
      top: -28px;
      height: 21px;
      line-height: 20px;
      padding-right: 6px;
   }
   .text {
      @include app-font--price(30px);
   }
   &.used-in-cart-page {
      circle {
         fill: var(--app-color--green-dark);
      }
      path:not(:last-child) {
         fill: var(--app-color--green-dark);
      }
      path:last-child {
         stroke: var(--app-color--green-dark);
      }
      .mat-chip.mat-standard-chip .badge {
         background: var(--app-color--red);
         color: var(--app-color--white);
      }
   }
}

.kiosk-product-search-box {
   .mat-icon {
      color: var(--app-color--green-dark) !important;
   }
}

.kiosk-product-search-result-view {
   color: var(--app-color--black);
   .title {
      @include app-font--title(26px);
      color: var(--app-color--green-dark);
   }
   .item {
      &:not(:hover) {
         letter-spacing: 0.3px;
      }
      &:hover {
         letter-spacing: -0.2px;
      }
      .found-word {
         color: var(--app-color--red) !important;
      }
   }
   .action-bar .mat-button {
      @include app-button--red(26px);
   }
   .no-item-found-section {
      color: var(--app-color--green-dark);
      .mat-icon {
         color: var(--app-color--red) !important;
      }
   }
}

.kiosk-chat-invite {
   &:not(.used-in-toolbar) {
      .question {
         color: var(--app-color--red);
      }
   }
   &.used-in-toolbar {
      .mat-icon-button .mat-icon {
         color: var(--app-color--red);
      }
   }
}

.kiosk-chat-box {
   .kiosk-toolbar {
      .custom-content {
         right: 25px;
      }
   }

   .title-bar {
      .title-icon {
         color: var(--app-color--red);
      }
      .title-label {
         @include app-font--title(35px);
         color: var(--app-color--red);
      }
      button {
         color: var(--app-color--green-dark);
      }
   }

   .message-list {
      .message-container {
         .message-panel {
            .author-panel {
               @include app-font--title(22px);
            }
            .content-panel {
               .block-of-pdf-link {
                  .mat-icon {
                     background: var(--app-color--white);
                     color: var(--app-color--green-dark);
                  }
               }
               .block-of-proposed-question,
               .block-of-available-assistant {
                  background: var(--app-color--red);
                  color: var(--app-color--white);
               }
            }
         }
         &:not(.user-message) {
            .message-panel {
               background: var(--app-color--yellow-light);
            }
         }
         &.user-message {
            .message-panel {
               background: var(--app-color--green-lighter);
            }
         }
         .block-of-text {
            th {
               background: var(--app-color--green-dark);
            }
         }
      }
   }

   .input-area {
      background-color: var(--app-color--green-dark);
      padding: 15px;
   }

   .input-container .send-button .mat-icon {
      color: var(--app-color--green-dark) !important;
   }
}

.kiosk-quantity-editor {
   .mat-icon-button {
      @include kiosk-icon-button(55px);
      border-radius: 0;
      background: var(--app-color--green-dark);
   }
}

.kiosk-slides-carousel {
   .navigation-button .mat-fab {
      box-shadow: none !important;
      .mat-ripple {
         display: none;
      }
   }
}

.kiosk-busy-indicator {
   label {
      color: var(--app-color--green-dark);
      @include app-font--title(22px, 0.5px);
   }
   .mat-spinner circle {
      stroke: var(--app-color--yellow);
   }
}

.kiosk-information-box,
.kiosk-confirmation-box,
.kiosk-input-box {
   .mat-dialog-title {
      color: var(--app-color--green-dark);
      @include app-font--title(42px);
      text-transform: none;
   }
   .mat-dialog-content {
      color: var(--app-color--green-darker);
   }
   .mat-button {
      @include app-button--green-dark(26px);
      min-width: 150px;
   }
}

.kiosk-information-box {
   &[kiosk-context="product-not-found"] {
      .mat-dialog-title {
         color: var(--app-color--red);
      }
   }
}

.kiosk-input-box {
   .mat-dialog-title {
      color: var(--app-color--red);
   }
   .field {
      .prompt {
         @include app-font--title(24px);
         color: var(--app-color--green-dark);
      }
   }
}

.kiosk-user-idle-state {
   .title {
      color: var(--app-color--green-dark);
      @include app-font--title(42px);
   }
   .message {
      color: var(--app-color--green-darker);
   }
   .countdown .seconds {
      color: var(--app-color--green-darker);
      font-size: 28px;
   }
   .mat-spinner circle {
      stroke: var(--app-color--yellow);
   }
}

.kiosk-product-media-view {
   kiosk-product-slides {
      width: 1760px;
   }
}

kiosk-external-content-page {
   .email-button {
      .mat-icon {
         color: var(--app-color--yellow);
      }
   }
}

.kiosk-background-image,
.kiosk-html-image {
   &.loading {
      opacity: 0.5;
   }
}
