// Fonts
@import "fonts.scss";

// NPM packages
@import "~@videogular/ngx-videogular/fonts/videogular.css";
@import "~nouislider/dist/nouislider.min.css";
@import "kiosk-core.scss";

// Application specific
:root {
   --app-color--white: #ffffff;
   --app-color--grey-lighter: rgb(245, 245, 245);
   --app-color--grey-light: rgb(219, 219, 219);
   --app-color--grey: #bfbfbf;
   --app-color--beige: rgb(229, 224, 206);
   --app-color--yellow-lightest: #fdebd1;
   --app-color--yellow-light: #ffcf14;
   --app-color--yellow: #ffab00;
   --app-color--navy-blue: rgb(0, 122, 201);
   --app-color--red: #da291c;
   --app-color--maroon: #93243d;
   --app-color--green-lightest: #f1f3f4;
   --app-color--green-lighter: #e0ede8;
   --app-color--green-light: rgb(166, 191, 191);
   --app-color--green-dark: #0d5257;
   --app-color--green-darker: rgb(51, 51, 51);
   --app-color--black: #000000;
}   
