@import "~tutch-kiosk-core/styles/_mixins.scss";

// Fonts
@mixin app-font--title($size: medium, $letter-spacing: 1px) {
   font-family: "Futura";
   font-size: $size !important;
   letter-spacing: $letter-spacing;
}

@mixin app-font--price($size: medium) {
   font-family: "Challenge";
   font-size: $size !important;
}

// Controls
@mixin app-button--base($font-size, $font-weight, $background, $color) {
   @include kiosk-font($font-size, $font-weight);
   background: $background;
   color: $color;
   border-radius: 0;
   padding: var(--kiosk-button-padding);
}
@mixin app-button--red($font-size, $font-weight: bold) {
   @include app-button--base($font-size, $font-weight, var(--app-color--red), var(--app-color--white));
}
@mixin app-button--yellow($font-size, $font-weight: bold) {
   @include app-button--base($font-size, $font-weight, var(--app-color--yellow), var(--app-color--black));
}
@mixin app-button--green-dark($font-size, $font-weight: bold) {
   @include app-button--base($font-size, $font-weight, var(--app-color--green-dark), var(--app-color--white));
}

@mixin app-checkbox($size: 30px) {
   @include kiosk-checkbox(
      $size,
      $border-radius: 2px,
      $border: 2px solid var(--app-color--green-dark),
      $checkmark-color: var(--app-color--white)
   );
   ::ng-deep {
      .mat-checkbox-label {
         font-size: 18px;
         margin-left: 5px;
         margin-top: 4px;
         white-space: normal;
         font-family: var(--kiosk-font-family-default) !important;
      }
   }
   &.mat-checkbox-checked ::ng-deep {
      .mat-checkbox-inner-container, .mat-checkbox-background {
         background-color: var(--app-color--green-dark);
      }
   }
}
