$font-path: "../assets/fonts";

@font-face {
   font-family: 'Helvetica Neue';
   src: url('#{$font-path}/HelveticaNeueLTW05-55Roman.woff2') format('woff2');
   font-weight: normal;
}

@font-face {
   font-family: 'Helvetica Neue';
   src: url('#{$font-path}/HelveticaNeueLTW05-75Bold.woff2') format('woff2');
   font-weight: bold;
}

@font-face {
   font-family: 'Futura';
   src: url('#{$font-path}/FuturaLTW05-Heavy.woff2') format('woff2');
   font-weight: 800;
}

@font-face {
   font-family: 'Challenge';
   src: url('#{$font-path}/ChallengeForBunnings-XtraBd.woff2') format('woff2');
   font-weight: bold;
}
